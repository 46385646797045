<template>
    <AppLayout>
        <RequireLogin>
            <v-row justify="center" class="py-5" v-show="!isSubscribed">
                <h1 class="text-h6 font-weight-light">Personal Subscription</h1>
            </v-row>
            <v-row justify="center" class="py-5 text-center" v-show="isAccountCreateError">
                <h1 class="text-h6 font-weight-light red--text">Request failed</h1>
            </v-row>
            <v-row justify="center" class="py-5 text-center" v-show="isPaymentError">
                <h1 class="text-h6 font-weight-light red--text">Payment failed</h1>
            </v-row>
            <v-row justify="center" class="py-5" v-show="isSubscribed">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                    <h1 class="text-h6 font-weight-light text-center">Thank you</h1>
                    <p class="text-body-1 font-weight-light text-center pt-10 pa-5">Click the button below to continue to the dashboard.</p>
                    <v-row justify="center">
                        <v-btn class="pa-5 text-center blue white--text" elevation="12" to="/dashboard">Continue</v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-show="!isSubscribed && !isBilling">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5" id="payment-form">
                        <v-form v-model="accountForm" @submit="continueToBilling" onSubmit="return false;" @keyup.enter.native="continueToBilling">
                            <v-row justify="center" class="pb-2">
                                <p class="text-h6 font-weight-light">Account Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="name"
                                    label="Your name"
                                    :rules="nameRules"
                                    required
                                    outlined
                                    autofocus
                                ></v-text-field>
                            </v-row>
                            <v-row justify="center" class="pt-3 pb-5">
                                <v-btn elevation="12" class="blue white--text" @click="continueToBilling" :disabled="!accountForm || !isClientReady">Continue</v-btn>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-show="!isSubscribed && isBilling">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5" id="payment-form">
                        <v-form v-model="billingForm" ref="billingFormRef" @submit="validateBillingForm" onSubmit="return false;" @keyup.enter.native="validateBillingForm">
                            <v-row justify="center" class="pb-2">
                                <p class="text-h6 font-weight-light">Billing Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="address"
                                    label="Address"
                                    :rules="addressRules"
                                    outlined
                                    required
                                    ref="addressInputRef">
                                </v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="city"
                                    label="City"
                                    :rules="cityRules"
                                    outlined
                                    required>
                                </v-text-field>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="px-5">
                                    <v-text-field
                                        v-model="state"
                                        label="State"
                                        :rules="stateRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-5">
                                    <v-text-field
                                        v-model="zipCode"
                                        label="Zip Code"
                                        :rules="zipCodeRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5">
                                <v-combobox
                                    v-model="defaultCountry"
                                    :items="countries"
                                    label="Country"
                                    default="United States"
                                    :rules="countryRules"
                                    outlined
                                    required
                                ></v-combobox>
                            </v-row>
                            <v-row justify="center" class="pt-6 pb-2 text-body-2 text-center font-weight-light">
                                <p>This card will be charged <strong>$7.00</strong> monthly</p>
                            </v-row>
                            <v-row justify="center" class="pt-2 px-5">
                                <div id="card-element" style="width: 100%">
                                    <!-- Stripe Element will be inserted here -->
                                </div>
                                <div id="card-errors" role="alert" class="red--text pt-1">
                                    <!-- Used to display form errors -->
                                </div>
                            </v-row>
                            <v-row justify="center" class="pt-2 pb-4">
                                <v-btn elevation="12" class="blue white--text" @click="subscribe" :disabled="!billingForm || !isCreditCardFormComplete">Subscribe</v-btn>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
    </AppLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import loginshield from '@/client';
import { loadStripeJavascript } from '@/sdk/stripe-util';
import RequireLogin from '@/components/RequireLogin.vue';
import AppLayout from '@/components/AppLayout.vue';
import { isValidName, /* isValidEmail, */ compact } from '@/sdk/input';

let stripe;
let card;

export default {
    components: {
        RequireLogin,
        AppLayout,
    },
    data() {
        return {
            isClientReady: false,
            isPaymentError: false,
            isAccountCreateError: false,
            isSubscribed: false,
            isBilling: false,
            accountForm: false, // true when accountForm is complete and inputs are valid
            billingForm: false,
            isCreditCardFormComplete: null,
            defaultCountry: 'United States',
            name: null,
            email: null,
            address: null,
            city: null,
            state: null,
            zipCode: null,
            stripeObject: { name: null, value: null },
            countries: [
                'United States',
            ],
            nameRules: [
                (v) => isValidName(v) || 'Enter the name that should appear on the invoice',
            ],
            addressRules: [
                (v) => (v && compact(v).length > 0) || 'Enter address',
            ],
            cityRules: [
                (v) => (v && compact(v).length > 0) || 'Enter city',
            ],
            stateRules: [
                (v) => (v && compact(v).length > 0) || 'Enter state',
            ],
            zipCodeRules: [
                (v) => (v && compact(v).length > 0) || 'Enter zip code',
            ],
            countryRules: [
                (v) => (v && compact(v).length > 0) || 'Enter country',
            ],
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            stripeTokenPublicKey: (state) => state.serviceContext.stripeTokenPublicKey,
        }),
        ...mapGetters({
            currentAccount: 'account',
            nonEnterpriseAccountList: 'nonEnterpriseAccountList',
        }),
        compactNonEnterpriseAccountNames() {
            return this.nonEnterpriseAccountList.map((item) => compact(item.name));
        },
    },

    watch: {
        isReady(value, oldValue) {
            console.log('subscribePersonal: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        init() {
            if (typeof this.currentAccount === 'object') {
                this.name = this.currentAccount.name;
                if (typeof this.currentAccount.verified === 'object') {
                    if (typeof this.currentAccount.verified.email === 'object' && this.currentAccount.verified.email.length > 0) {
                        this.email = this.currentAccount.verified.email[0];
                    }
                }
            }
        },
        /**
         * Call this only after the stripe javascript is loaded from stripe server and the
         * stripe instance is initialized with the stripe public key.
         */
        initStripe() {
            stripe = new Stripe(this.stripeTokenPublicKey); // eslint-disable-line no-undef

            // Create an instance of Elements.
            const elements = stripe.elements();

            // Add an instance of the card Element into the `card-element` <div>. (May want to change)
            const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };

            // Create an instance of the card Element.
            card = elements.create('card', { style });

            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#card-element');

            // Handle real-time validation errors from the card Element.
            card.addEventListener('change', (event) => {
                const displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                    this.isCreditCardFormComplete = false;
                } else {
                    displayError.textContent = '';
                    this.isCreditCardFormComplete = true;
                }
            });
        },
        async continueToBilling() {
            // API Calls to make account
            this.isAccountCreateError = false;
            this.isBilling = true;
            this.initStripe();
            window.scrollTo(0, 0);
            this.$nextTick(() => this.$refs.addressInputRef.focus());
            this.$nextTick(() => this.$refs.billingFormRef.reset());
        },
        validateBillingForm() {
            if (this.$refs.billingFormRef.validate()) {
                this.subscribe();
            }
        },
        // Stripe Elements
        subscribe() {
            this.isAccountCreateError = false;
            this.isPaymentError = false;
            const {
                name, email,
            } = this;
            const address = {
                line1: this.address, city: this.city, state: this.state, country: this.country, postal_code: this.zipCode,
            };
            stripe.createToken(card).then(async (result) => {
                if (result.error) {
                    // Inform the user if there was an error.
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    console.log('stripeToken = %o', result.token.id);
                    this.$store.commit('loading', { stripeProcessingTransaction: true });
                    const response = await loginshield.stripe.createSubscription({
                        stripeToken: result.token.id, subscriptionType: 'Personal', accountName: name, name, email, address, accountId: this.currentAccount.id,
                    });
                    console.log('response: %o', response);
                    if (response.fault || response.error) {
                        if (response.error === 'payment-failed') {
                            this.isPaymentError = true;
                        } else {
                            this.isAccountCreateError = true;
                        }
                    } else {
                        await this.$store.dispatch('loadAccount');
                        await this.$store.dispatch('switchAccount', response.accountId);
                        this.isSubscribed = true;
                        window.scrollTo(0, 0);
                    }

                    this.$store.commit('loading', { stripeProcessingTransaction: false });
                    console.log('createSubscription: %o', response);
                }
            });

            // const {
            //     name, email,
            // } = this;
            // const address = {
            //     line1: this.address, city: this.city, state: this.state, country: this.country, postal_code: this.zipCode,
            // };
            // stripe.createToken(card).then(async (result) => {
            //     if (result.error) {
            //         // Inform the user if there was an error.
            //         const errorElement = document.getElementById('card-errors');
            //         errorElement.textContent = result.error.message;
            //     } else {
            //         // Send the token to your server.
            //         console.log('stripeToken = %o', result.token.id);
            //         this.isLoading = true;
            //         const response = await loginshield.stripe.createSubscription({
            //             stripeToken: result.token.id, subscriptionType: 'Personal', accountName: name, name, email, address, accountId: this.currentAccount.id,
            //         });

            //         this.isLoading = false;
            //         this.isSubscribed = true;
            //         console.log('createPaymentIntent: %o', response);
            //     }
            // });
        },
    },
    created() {
        this.$store.commit('loading', { stripeClient: true });
        console.log('loading stripeClient');
        loadStripeJavascript(document, () => {
            this.isClientReady = true;
            this.$store.commit('loading', { stripeClient: false });
            console.log('loading stripeClient stopped');
        });
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>

<style scoped>
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
